export const menuItems = [
  {
    title: 'Panel',
    slug: '/',
    active: true,
    subMenu: false
  },
  // {
  //   title: 'Clientes',
  //   slug: '/singles/grid',
  //   active: true,
  //   subMenu: false
  // },
  {
    title: 'Pedidos',
    slug: '/orders/grid',
    active: true,
    subMenu: false
  },
  {
    title: 'Administrar',
    slug: '',
    active: false,
    subMenu: false
  },
  {
    title: 'Usuarios',
    slug: '/admin/grid',
    active: true,
    subMenu: true
  },
  {
    title: 'Cerrar',
    slug: '/logout',
    active: true,
    subMenu: false
  }
]
