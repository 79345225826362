// PropTypes
import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import LoginForm from 'forms/login/loginForm';

export default function LoginComp({ loading, error, handleSubmitLogin }) {
  return (
    <div className="w-full max-w-lg">
      {error && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
          role="alert"
        >
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">
            ¡Usuario y contraseña incorrectos!
          </span>
        </div>
      )}
      <LoginForm loading={loading} handleSubmit={handleSubmitLogin} />
      <div className="text-center mt-6">
        <a href="#" className="text-blue-300">¿Ha olvidado su contraseña? Pinche aquí</a>
      </div>
    </div>
  );
}

LoginComp.propTypes = {
  handleSubmitLogin: PropTypes.func.isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool
};
