// PropTypes & HOOKS
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

// COMPONENTS
import OrdersTable from 'components/ui/Table/OrdersTable'
import ColumnFilter from 'components/ui/Table/ColumnFilter'

export default function OrdersCompGrid({ items, handleClick }) {
  const [getTableData, setTableData] = useState([])
// Columnas:
  // const columns = [
  //   {
  //     Header: 'Usuario',
  //     accessor: 'Admin_UserName',
  //     Filter: ColumnFilter
  //   },
  //   {
  //     Header: 'Nombre',
  //     accessor: 'Admin_Name',
  //     Filter: ColumnFilter
  //   }
  // ]
  // Columnas:
const columns = [
  {
    Header: 'Nº Pedido Cliente',
    accessor: 'Numero_pedido_Cliente',
    Filter: ColumnFilter,
    Type: 'Numero_pedido_Cliente',
    Key: 'Numero_pedido_Cliente'
  },
  {
    Header: 'Nº Pedido Technoform',
    accessor: 'Numero_pedido_Technoform',
    Filter: ColumnFilter,
    Type: 'Numero_pedido_Technoform',
    Key: 'Numero_pedido_Technoform'
  },
  {
    Header: 'Posición',
    accessor: 'Posicion',
    Filter: ColumnFilter,
    Type: 'Posicion',
    Key: 'Posicion'
  },
  {
    Header: 'Referencia Cliente',
    accessor: 'Referencia_Cliente',
    Filter: ColumnFilter,
    Type: 'Referencia_Cliente',
    Key: 'Referencia_Cliente'
  },
  {
    Header: 'Referencia Technoform',
    accessor: 'Referencia_Technoform',
    Filter: ColumnFilter,
    Type: 'Referencia_Technoform',
    Key: 'Referencia_Technoform'
  },
  {
    Header: 'Confección',
    accessor: 'Confeccion',
    Filter: ColumnFilter,
    Type: 'Confeccion',
    Key: 'Confeccion'
  },
  {
    Header: 'Material',
    accessor: 'Material',
    Filter: ColumnFilter,
    Type: 'Material',
    Key: 'Material'
  },
  {
    Header: 'Metros',
    accessor: 'Metros',
    Filter: ColumnFilter,
    Type: 'Metros',
    Key: 'Metros'
  },
  {
    Header: 'Longitud',
    accessor: 'Longitud',
    Filter: ColumnFilter,
    Type: 'Longitud',
    Key: 'Longitud'
  },
  {
    Header: 'Varillas por Paquete',
    accessor: 'Varillas_Paquete',
    Filter: ColumnFilter,
    Type: 'Varillas_Paquete',
    Key: 'Varillas_Paquete'
  },
  {
    Header: 'Fecha Pedido',
    accessor: 'Fecha_Pedido',
    Filter: ColumnFilter,
    Type: 'Fecha_Pedido',
    Key: 'Fecha_Pedido'
  },
  {
    Header: 'Fecha Entrega',
    accessor: 'Fecha_Entrega',
    Filter: ColumnFilter,
    Type: 'Fecha_Entrega',
    Key: 'Fecha_Entrega'
  },
  {
    Header: 'Cant. Estimada Pallets',
    accessor: 'Cantidad_Estimada',
    Filter: ColumnFilter,
    Type: 'Cantidad_Estimada',
    Key: 'Cantidad_Estimada'
  },
  {
    Header: 'Producción Extranjera',
    accessor: 'Produccion_Extranjera',
    Filter: ColumnFilter,
    Type: 'Produccion_Extranjera',
    Key: 'Produccion_Extranjera'
  }
];

  // Adaptar datos para la tabla:
  // useEffect(() => {
  //   if (items) {
  //     const itemsData = []
  //     console.log(items);
  //     items.forEach((item) => {
  //       itemsData.push({
  //         Orders_Id: item.Id,
  //         Orders_Name: item.Name,
  //         Orders_Surnames: item.Surnames,
  //         Orders_DNI: item.DNI,
  //         Orders_IdInterno: item.Idinterno,
  //         Orders_Email: item.Email
  //       })
  //     })
  //     setTableData(itemsData)
  //   }
  // }, [items])

// Adaptar datos para la tabla:
    useEffect(() => {
      if (items) {
        const itemsData = []
      //   items.data.forEach((item) => {
        items.forEach((item) => {
        itemsData.push({
          Numero_pedido_Cliente: item.Numero_pedido_Cliente,
          Numero_pedido_Technoform: item.lineapedido_numeropedido,
          Posicion: item.Posicion,
          Referencia_Cliente: item.Referencia_Cliente,
          Referencia_Technoform: item.Referencia_Technoform,
          Confeccion: item.Confeccion,
          Material: item.Material,
          Metros: item.Metros,
          Longitud: item.Longitud,
          Varillas_Paquete: item.Varillas_Paquete,
          Fecha_Pedido: item.Fecha_Pedido,
          Fecha_Entrega: item.Fecha_Entrega,
          Cantidad_Estimada: item.Cantidad_Estimada,
          Produccion_Extranjera: item.Produccion_Extranjera,
        })
      })
  // console.log(items);
  //     items.forEach((item) => {
  //         itemsData.push({
  //           Admin_Id: item.Numero_pedido_Cliente,
  //           Admin_UserName: item.lineapedido_numeropedido,
  //           Admin_Name: item.Posicion,
  //           Admin_Password: item.Referencia_Cliente,
  //           Admin_Email: item.Confeccion
  //         })
  //       })
      
      console.log(itemsData);
        setTableData(itemsData);
      }
    }, [items]);

  return (
    <div className="overflow-x-auto">
      <div className="align-middle inline-block min-w-full">
        <div className="overflow-hidden">
          {getTableData.length > 0 && (
            <OrdersTable
              data={getTableData}
              columns={columns}
              handleClick={handleClick}
            />
          )}
        </div>
      </div>
    </div>
  )
}

OrdersCompGrid.propTypes = {
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func,
  value: PropTypes.any
}
