import PropTypes from 'prop-types'

// Images
import logoTechform from 'assets/img/logo_technoform.png'

export default function LogoTechnoform({ link }) {
  return (
    <a href={link} className="h-full flex flex-row justify-start items-center">
      <div className="h-full w-3/5 ml-2 mr-6 flex items-center">
        <img className="w-12/12 max-w-md" src={logoTechform} />
      </div>
    </a>
  )
}
LogoTechnoform.propTypes = {
  link: PropTypes.string
}
