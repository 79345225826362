import PropTypes from 'prop-types'
import flecha from 'assets/img/imageInvert.png'
import logoExcel from 'assets/img/logoExcel.png';
import logoExcelRound from 'assets/img/Excel-2-icon.png';
// import { UserContext } from 'contexts/UserContext';

import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';


export default function OrdersTableBar({
  previousPage,
  canPreviousPage,
  pageIndex,
  pageOptions,
  nextPage,
  canNextPage,
  pageSize,
  setPageSize,
  datos
}) {
  const rowsEmpty = datos.length === 0;
  const exportToExcel = () => {
  // Mapeo de títulos originales a nuevos títulos sin guiones bajos
  const titlesMapping = {
    Numero_pedido_Cliente: 'Número pedido Cliente',
    Numero_pedido_Technoform: 'Número pedido Technoform',
    Posicion: 'Posición',
    Referencia_Cliente: 'Referencia Cliente',
    Referencia_Technoform: 'Referencia Technoform',
    Confeccion: 'Confección',
    Material: 'Material',
    Metros: 'Metros',
    Longitud: 'Longitud',
    Varillas_Paquete: 'Varillas Paquete',
    Fecha_Pedido: 'Fecha Pedido',
    Fecha_Entrega: 'Fecha Entrega',
    Cantidad_Estimada: 'Cantidad Estimada',
    Produccion_Extranjera: 'Producción Extranjera',
  };

  // Formatear las fechas en formato español (DD/MM/YYYY)
  const datosFormateados = datos.map(item => ({
    ...item,
    Fecha_Pedido: item.Fecha_Pedido ? formatDateToSpanish(item.Fecha_Pedido) : '',
    Fecha_Entrega: item.Fecha_Entrega ? formatDateToSpanish(item.Fecha_Entrega) : ''
  }));

  // Crear la hoja de trabajo con los nuevos títulos
  const worksheet = XLSX.utils.json_to_sheet(datosFormateados);
  
  // Reemplazar los títulos en la hoja de trabajo
  const newHeaders = Object.keys(titlesMapping).map(key => titlesMapping[key]);
  worksheet['!cols'] = newHeaders.map(() => ({ wch: 20 })); // Ajustar el ancho de las columnas
  newHeaders.forEach((header, index) => {
    worksheet[XLSX.utils.encode_cell({ r: 0, c: index })].v = header; // Asignar nuevos títulos
  });

  const workbook = {
    Sheets: { 'data': worksheet },
    SheetNames: ['data']
  };
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
  saveAs(data, 'pedidos_clientes.xlsx');
};
// Función para formatear la fecha en DD/MM/YYYY
const formatDateToSpanish = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0'); // Obtener el día con dos dígitos
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Obtener el mes con dos dígitos (0-11)
  const year = date.getFullYear(); // Obtener el año
  return `${day}/${month}/${year}`; // Retornar en formato DD/MM/YYYY
};
  return (
    <div className="text-center print:hidden border-t-4 border-customtechnoform px-2 py-2 bg-white flex items-center justify-between">
      <div className="float-left text-left font-bold flex justify-center items-center pt-1">
      <button
          onClick={exportToExcel}
          disabled={rowsEmpty} 
          hidden={rowsEmpty}
          className="font-bold rounded-lg text-white disabled:opacity-0 hover:{`handleHover`}"
          style={{ width: '85px', marginRight: '5px', height: '85px' }}
        ><img
            src={logoExcel}
            alt="icon"
          /></button>
</div>

      <div>
        
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="font-bold rounded-full bg-customtechnoform text-white disabled:opacity-0"
          style={{ width: '24px', marginRight: '7px', height: '24px' }}
        >
          <img
            src={flecha}
            width="15"
            height="15"
            className="p-px"
            style={{ transform: 'rotate(180deg)', margin: 'auto' }}
          />
        </button>
        <span>
          Pàgina <strong>{pageIndex + 1}</strong> de{' '}
          <strong>
            {pageOptions.length} {' ('}
            {datos.length} registres{')'}
          </strong>
        </span>
        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="font-bold rounded-full bg-customtechnoform text-white disabled:opacity-0"
          style={{ width: '24px', marginLeft: '7px', height: '24px' }}
        >
          <img
            src={flecha}
            width="15"
            height="15"
            className="p-px"
            style={{ margin: 'auto' }}
          />
        </button>
      </div>

      <select
        className="float-right"
        value={pageSize}
        onChange={(e) => setPageSize(Number(e.target.value))}
      >
        {[25, 75, 150, 250, 500].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            {pageSize} registres
          </option>
        ))}
      </select>
    </div>
  )
  
}

OrdersTableBar.propTypes = {
  previousPage: PropTypes.func,
  canPreviousPage: PropTypes.bool,
  pageIndex: PropTypes.number,
  pageOptions: PropTypes.array,
  nextPage: PropTypes.func,
  canNextPage: PropTypes.bool,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  datos: PropTypes.array
}
