// PropTypes & HOOKS
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// HOOKS (Routing)
import { Switch, Route, Redirect } from 'wouter'

// COMPONENTS
import Layout from 'components/layout/Layout'
import Login from 'pages/Login/Login'
import Logout from 'pages/Login/Logout'
import Dashboard from 'pages/Dashboard/Dashboard'
import SinglesViewGrid from 'pages/Singles/SinglesViewGrid'
import AdminViewGrid from 'pages/Admin/AdminViewGrid'
import MailsViewGrid from 'pages/Mails/MailsViewGrid'
import MailSplitViewGrid from 'pages/Mails/MailSplit/MailSplitViewGrid'
import SinglesViewDetail from 'pages/Singles/SinglesViewDetail'
import AdminViewDetail from 'pages/Admin/AdminViewDetail'
import MailsViewDetail from 'pages/Mails/MailsViewDetail'
import OrdersViewGrid from 'pages/Orders/OrdersViewGrid'


// CONTEXTS
import { UserContext } from 'contexts/UserContext'

// Rutas privadas que se pueden usar si se ha iniciado sesión:
const PrivateRoute = ({ isLoggedIn, ...props }) =>
  isLoggedIn ? (
    <Layout>
      <Route {...props} />
    </Layout>
  ) : (
    <Redirect to="/login" />
  )

// Ruta 'LOG IN' que se usa si no se ha iniciado sesión:
const LoginRoute = ({ isLoggedIn, ...props }) =>
  isLoggedIn ? <Redirect to="/" /> : <Route path="/login" component={Login} />

export default function App() {
  const { user } = useContext(UserContext)
  const isLoggedIn = user && !!user.token

  return (
    <>
      <Switch>
        {/* <- LOG IN / LOG OUT */}
        <LoginRoute isLoggedIn={isLoggedIn} path="/login" component={Login} />

        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/logout"
          component={Logout}
        />
        {/* LOG IN / LOG OUT -> */}

        {/* ######################################################################### */}

        {/* <- PANEL */}
        <PrivateRoute isLoggedIn={isLoggedIn} path="/" component={Dashboard} />
        {/* PANEL -> */}

        {/* ######################################################################### */}

        {/* <- CLIENTES */}
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/singles/grid"
          component={SinglesViewGrid}
        />

        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/singles/:Id/:idMail?/:registerSingle?"
          component={SinglesViewDetail}
        />
        {/* CLIENTES -> */}

        {/* ######################################################################### */}

        {/* <- FACTURAS */}
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/mails/grid"
          component={MailsViewGrid}
        />
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/orders/grid"
          component={OrdersViewGrid}
        />

        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/mails/:id"
          component={MailsViewDetail}
        />

        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/mails/:idMail/mail-split"
          component={MailSplitViewGrid}
        />
        {/* FACTURAS -> */}

        {/* ######################################################################### */}

        {/* <- USUARIOS */}
        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/admin/grid"
          component={AdminViewGrid}
        />

        <PrivateRoute
          isLoggedIn={isLoggedIn}
          path="/admin/:id"
          component={AdminViewDetail}
        />
        {/* USUARIOS -> */}
      </Switch>

      <ToastContainer />
    </>
  )
}

PrivateRoute.propTypes = {
  isLoggedIn: PropTypes.bool
}

LoginRoute.propTypes = {
  isLoggedIn: PropTypes.bool
}
