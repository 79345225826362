// PropTypes & HOOKS
import React from 'react'
import PropTypes from 'prop-types'
import { useLocation, Link } from 'wouter'

// DATA
import { menuItems } from 'data/menuItems'

// ICONS
import dashboardIcon from 'assets/icons/iconDashboard.svg'
import singleIcon from 'assets/icons/iconSingle.svg'
import adminIcon from 'assets/icons/headphones.png'
import homeIcon from 'assets/icons/home.svg'
import iconKey from 'assets/icons/iconKey.png'
import iconMail from 'assets/icons/mail.png'
import iconLogout from 'assets/icons/logout.png'

export default function Menu({ device, handleMobileMenu }) {
  const [location] = useLocation()

  if (device === 'mobile') {
    return (
      <>
        {menuItems.map((item) => (
          <Link
            key={item.slug}
            href={item.slug}
            onClick={() => handleMobileMenu()}
          >
            <a
              className={`${
                item.slug === location
                  ? 'bg-secondary text-white'
                  : 'text-white hover:bg-secondary hover:text-white'
              } block px-3 py-3 rounded-md text-base font-medium`}
            >
              {item.title}
            </a>
          </Link>
        ))}
      </>
    )
  } else {
    return (
      <>
        {menuItems.map((item) => (
          <Link href={item.slug} key={item.slug}>
            <a
              className={`flex px-4 py-2 mt-2 text-sm font-semibold rounded
                  ${
                    item.slug === location
                      ? 'bg-blue-400 text border-blue-700 text-blue-900 font-bold text-white'
                      : 'bg-transparent text-white'
                  }

                  ${
                    item.active
                      ? 'hover:text-white focus:text-blue-900 hover:bg-blue-400 hover:font-bold focus:bg-gray-200 hover:border hover:border-white'
                      : 'hover:cursor-default'
                  }
                       
                  ${item.subMenu ? 'ml-6' : 'ml-0'}

                  ${item.title === 'Administrar' && 'cursor-default'} 
              `}
            >
              <img
                src={
                  item.title === 'Panel'
                    ? dashboardIcon
                    : item.title === 'Clientes'
                    ? singleIcon
                    : item.title === 'Pedidos'
                    ? iconMail
                    : item.title === 'Administrar'
                    ? adminIcon
                    : item.title === 'Usuarios'
                    ? iconKey
                    : item.title === 'Cerrar'
                    ? iconLogout
                    : homeIcon
                }
                alt="customers"
                className="w-5 mr-2 h-5"
              />

              <span>{item.title.toUpperCase()}</span>
            </a>
          </Link>
        ))}
      </>
    )
  }
}

Menu.propTypes = {
  device: PropTypes.string,
  handleMobileMenu: PropTypes.func
}
