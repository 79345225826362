import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'wouter';
import useFetch from 'hooks/useFetch';
import { UserContext } from 'contexts/UserContext';
import LoginComp from 'components/Login/LoginComp';
import backgroundImage from 'assets/img/bg-technoform.png';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Para accesibilidad, establece el elemento principal de la aplicación

export default function Login() {
  // Get user context
  const { setUser } = useContext(UserContext);

  // Router (to do redirects)
  const [, setLocation] = useLocation();

  // Form login data
  const [loginData, loginAction] = useFetch();

  // Form operator data
  const [loginOperatorData, loginOperatorAction] = useFetch();

  // State for modal visibility and input value
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');

  // Trigger login data (to set userContext and show modal)
  useEffect(() => {
    // If Login OK
    console.log(loginData);
    // setLocation('/');
    if (loginData.data) {
      // Set user info to User context (context + localstorage)
      console.log(loginData.data);
      // setUser(loginData.data);
      // setLocation('/');
      // Show modal for verification code
      setModalIsOpen(true);
      // setUser(loginData.data);
      // Redirect
      // setLocation('/');
    }
    // If Login Operator OK
    if (loginOperatorData.data) {
      console.log("loginOperatorData OK");
      console.log(loginOperatorData);
      if (loginOperatorData.data) {
      console.log("loginOperatorData data IF OK");
      console.log(loginOperatorData.data);
      // Set user info to User context (context + localstorage)
      setUser(loginOperatorData.data);
      // Redirect
      // setLocation('/')
      setLocation('/orders/grid');

    }
    }
  }, [loginData, loginOperatorData, setUser, setLocation]);

  const handleSubmitLogin = async (values) => {
    const loginAPIParams = {
      url: 'auth',
      method: 'POST',
      body: values,
      messageKo: 'Usuario y/o contraseña incorrectos'
    };
    // Fetch api Login
    console.log("loginAPIParams");
    loginAction(loginAPIParams);
  };

  const handleSubmitLoginPin = async (values) => {
    console.log(verificationCode);
    // const { user } = useContext(UserContext)
  // const isLoggedIn = user && !!user.token
    console.log("loginData");
    console.log(loginData.data);
    // console.log(password);
    const loginAPIParams2 = {
      url: 'auth/pin',
      method: 'POST',
      body: { code: verificationCode,
              email: loginData.data.email,
              password : loginData.data.password}, 
      messageKo: 'PIN incorrecte'
    };
    // Fetch api Login
    // console.log("loginPin");
    console.log("loginAPIParams2");
    loginOperatorAction(loginAPIParams2);
  };

  const handleVerifyCode = () => {
    alert(`Código de verificación ingresado: ${verificationCode}`);
    setModalIsOpen(false);
    // Aquí puedes manejar la verificación del código y cualquier lógica adicional
    // setLocation('/');
  };

  return (
    <div
      className="h-screen flex flex-col justify-center items-center bg-cover bg-center"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <style>{`
        .border-container {
          border: 20px solid #00CCCC;
          padding: 5%;
          position: relative;
          width: 95%;
          max-width: auto;
          height: 95%;
          max-height: auto;
        }
        .text-turquoise {
          color: #00CCCC;
          background-color: #0a0a0a;
          padding: 0 10px;
        }
        .absolute-text {
          position: absolute;
          top: -50px; 
          right: 90px;
          font-size: 50px;
          font-weight: bold;
          background: #1B3C87;
          padding: 0 10px;
        }
      `}</style>
      <div className="border-container relative p-12">
        <div className="absolute-text text-turquoise">
          TECHNOFORM
        </div>
        <div className="w-full flex justify-start mb-6 md:mb-12">
          <div>
            <h1 className="text-white text-4xl md:text-9xl font-bold">Technoform</h1>
            <h2 className="text-white text-2xl md:text-6xl font-bold">Extranet service</h2>
          </div>
        </div>
        <div className="w-full mb-6 md:mb-12">
          <LoginComp
            loading={loginData.loading}
            error={loginData.error}
            handleSubmitLogin={handleSubmitLogin}
          />
        </div>
        <div className="w-full mt-auto flex justify-start mt-12">
          <p className="text-white font-bold text-2xl md:text-4xl mb-4 md:mb-8">Soluciones aislantes para ventanas, puertas y fachadas</p>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Código de Verificación"
        style={{
          overlay: { backgroundColor: 'rgba(0, 0, 0, 0.75)' },
          content: { 
            top: '50%', 
            left: '50%', 
            right: 'auto', 
            bottom: 'auto', 
            marginRight: '-50%', 
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            borderRadius: '10px'
          }
        }}
      >
        <h2>Ingrese su código de verificación</h2>
        <input
          type="number"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
          placeholder="Código de verificación"
          className="p-2 border rounded w-full mb-4"
        />
        <button 
          onClick={handleSubmitLoginPin}
          className="p-2 bg-blue-500 text-white rounded w-full"
        >
          Verificar
        </button>
      </Modal>
    </div>
  );
}
