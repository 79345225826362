import { React, useState } from 'react'

// Components
import Menu from 'components/layout/Menu/Menu'

// Logo technoform
import Logo from 'components/ui/Logo/LogoTechnoform'

import Notifications from './Notifications/Notifications'

// Icons
import CloseMenuIcon from 'components/icons/CloseMenuIcon'
import MobileMenuIcon from 'components/icons/MobileMenuIcon'
// import SearchIcon from 'components/icons/SearchIcon'

export default function Navbar() {
  const [mobileMenu, setMobileMenu] = useState(false)

  const handleMobileMenu = () => {
    setMobileMenu(!mobileMenu)
  }

  return (
    <nav className="bg-customtechnoform border-b-8 border-customtechnoformline sticky top-0 z-50 print:hidden py-2">
      <div className="w-full mx-auto px-4 sm:px-6 lg:px-2">
        <div className="flex items-center justify-end h-24">
          <div className="mr-2 h-full">
              <Logo link="/" />
            </div>
          <div className="-mr-2 flex md:hidden">
            {/* Mobile menu button */}
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white focus:outline-none 
             "
              aria-controls="mobile-menu"
              aria-expanded="false"
              onClick={handleMobileMenu}
            >
              <span className="sr-only">Open main menu</span>
              {!mobileMenu ? (
                <MobileMenuIcon className="h-8 w-8" />
              ) : (
                <CloseMenuIcon className="h-8 w-8" />
              )}
            </button>
          </div>
        </div>
      </div>

      <div className={`md:hidden ${!mobileMenu && 'hidden'}`} id="mobile-menu">
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <Menu device="mobile" handleMobileMenu={handleMobileMenu} />
        </div>
        <div className="pt-4 pb-3 border-t border-red-600">
          <div className="flex items-center px-5">
            <Notifications device="mobile" />
          </div>
        </div>
      </div>
    </nav>
  )
}
