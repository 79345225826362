import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// React Table HOOK
import {
  useTable,
  useFilters,
  useSortBy,
  usePagination,
  useGlobalFilter
} from 'react-table'

// COMPONENTS
import FilterUp from 'components/icons/FilterUp'
import FilterDown from 'components/icons/FilterDown'
import OrdersTableBar from './OrdersTableBar'

// ICONS
import { BsPersonFill, BsListCheck } from 'react-icons/bs'

export default function OrdersTable({ data, columns, handleClick }) {
  // Estado para guardar los datos filtrados
  const [filteredData, setFilteredData] = useState(data)

  // Inicializar la tabla:
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setPageSize,
    state,
    prepareRow,
    rows
  } = useTable(
    { columns, data, initialState: { pageSize: 25 } },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  )

  const { pageIndex, pageSize } = state
// Actualizar el estado filteredData con las filas filtradas
  useEffect(() => {
    setFilteredData(rows.map(row => row.original)) // Guardar solo los datos filtrados
  }, [rows])

  return (
    <div className="flex flex-col">
      <div className="align-middle inline-block min-w-full">
        {/* Tabla: */}
        <table
          className="table-auto min-w-full border-2 customtechnoformline"
          {...getTableProps()}
        >
          {/* Cabecera */}
          <thead className="bg-blue-700">
            {headerGroups.map((headerGroup) => (
              <tr
                className="divide-x divide-dashed customtechnoformline"
                key={headerGroup.id}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    className="text-white py-2 hover:bg-customtechnoform font-ms-semi"
                    key={column.id}
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({ title: undefined })
                    )}
                  >
                    <div className="text-left text-sm ml-4">
                      {column.render('Header')}

                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FilterDown
                            width={10}
                            height={10}
                            className="inline-block ml-1 -mt-1"
                            fill="black"
                          />
                        ) : (
                          <FilterUp
                            width={10}
                            height={10}
                            className="inline-block ml-1 -mt-1"
                            fill="black"
                          />
                        )
                      ) : (
                        ''
                      )}
                    </div>

                    <div className="mx-4">
                      {column.canFilter && column.render('Filter')}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {/* Cuerpo */}
          <tbody
            style={{ borderColor: '#9CA3AF' }}
            className="bg-white divide-y divide-gray-200"
            {...getTableBodyProps()}
          >
            {page.map((row, index) => {
              prepareRow(row)

              return (
                <tr
                  key={row.id}
                  {...row.getRowProps()}
                  onClick={(e) => handleClick && handleClick(e, row.original)}
                  className={`hover:bg-blue-200 hover:font-bold cursor-pointer divide-x divide-dashed divide-blue-200
                    ${index % 2 === 0 && 'bg-blue-50'}`}
                >
                  {/* Renderiza las celdas de la fila */}
                  <td className="text-sm font-bold pl-4 py-4 w-1/7">
                    {row.original.Numero_pedido_Cliente}
                  </td>

            <td className="text-sm font-bold pl-4 py-4 w-1/7">
              {page[index].original.Numero_pedido_Technoform}
            </td>

            <td className="text-sm font-bold pl-4 py-4 w-1/7">
              {page[index].original.Posicion}
            </td>

            <td className="text-sm font-bold pl-4 py-4 w-1/6">
              {page[index].original.Referencia_Cliente}
            </td>

            <td className="text-sm font-bold pl-4 py-4 w-1/8">
              {page[index].original.Referencia_Technoform}
            </td>

            <td className="text-sm font-bold pl-4 py-4 w-1/7">
              {page[index].original.Confeccion}
            </td>

            <td className="text-sm font-bold pl-4 py-4 w-1/5">
              {page[index].original.Material}
            </td>

            <td className="text-sm font-bold pl-4 py-4 w-1/7">
              {page[index].original.Metros}
            </td>

            <td className="text-sm font-bold pl-4 py-4 w-1/7">
              {page[index].original.Longitud}
            </td>

            <td className="text-sm font-bold pl-4 py-4 w-1/7">
              {page[index].original.Varillas_Paquete}
            </td>

            <td className="text-sm font-bold pl-4 py-4 w-1/7">
              {new Date(page[index].original.Fecha_Pedido).toLocaleDateString()}
            </td>

            <td className="text-sm font-bold pl-4 py-4 w-1/7">
              {page[index].original.Fecha_Entrega
                ? new Date(page[index].original.Fecha_Entrega).toLocaleDateString()
                : 'No asignada'}
            </td>

            <td className="text-sm font-bold pl-4 py-4 w-1/7">
              {page[index].original.Cantidad_Estimada}
            </td>

            <td className="text-sm font-bold pl-4 py-4 w-1/7">
              {page[index].original.Produccion_Extranjera === 'X' ? 'Si' : 'No'}
            </td>
          </tr>
              )
            })}
          </tbody>

          {/* Pie: */}
          <tfoot>
             <tr>
              <td colSpan={columns.length} className="p-0 m-0">
                <OrdersTableBar
                  previousPage={previousPage}
                  canPreviousPage={canPreviousPage}
                  pageIndex={pageIndex}
                  pageOptions={pageOptions}
                  nextPage={nextPage}
                  canNextPage={canNextPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  datos={filteredData}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )
}

OrdersTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  handleClick: PropTypes.func
}
