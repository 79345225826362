// PropTypes & HOOKS
import PropTypes from 'prop-types';

// FORMIK HOOK
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

// ASSETS
import LockIcon from 'components/icons/LockIcon';
import EmailIcon from 'components/icons/EmailIcon';

export default function LoginForm({ loading, handleSubmit }) {
  // Esquema validación formulario:
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Email inválido').required('Introduzca un email'),
    password: Yup.string()
      .required('Introduzca una contraseña')
      .min(6, 'La contraseña es demasiado corta. Mínimo 6 caracteres')
  });

  return (
<Formik
  initialValues={{
    email: '',
    password: ''
  }}
  validationSchema={validationSchema}
  onSubmit={(values) => {
    handleSubmit(values);
  }}
>
  {({ errors, touched }) => (
    <Form className="w-full max-w-lg px-12">
      <div className="mb-6 flex items-center">
        <label
          className="block text-white text-md font-medium mr-4 w-1/4"
          htmlFor="email"
        >
          Usuario
        </label>
        <div className="relative inline-block text-gray-700 w-3/4">
          <Field
            name="email"
            id="email"
            className="shadow appearance-none border border-gray-300 rounded py-2 px-3 w-full"
            autoComplete="email"
          />
          <div className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
            <EmailIcon className="w-4 h-4 text-gray-600" />
          </div>
        </div>
      </div>
      {touched.email && errors.email && (
        <p className="text-red-500 text-xs italic mb-4">{errors.email}</p>
      )}
      <div className="mb-6 flex items-center">
        <label
          className="block text-white text-md font-medium mr-4 w-1/4"
          htmlFor="password"
        >
          Password
        </label>
        <div className="relative inline-block text-gray-700 w-3/4">
          <Field
            type="password"
            name="password"
            id="password"
            autoComplete="current-password"
            className="shadow appearance-none border border-gray-300 rounded py-2 px-3 w-full"
          />
          <div className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
            <LockIcon className="w-4 h-4 text-gray-600" />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end">
        <button
          type="submit"
          className="h-10 px-5 text-white transition-colors duration-150 bg-blue-700 rounded-lg focus:shadow-outline hover:bg-blue-800"
          disabled={loading && 'disabled'}
        >
          Validar
        </button>
      </div>
      {touched.password && errors.password && (
        <p className="text-red-500 text-xs italic mb-4">{errors.password}</p>
      )}
    </Form>

  )}
</Formik>

  );
  
}

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool
};
